import React from 'react';
import { Root } from './style';

import Layout from 'components/Layout/Dashboard';
import FlipBox from 'components/Common/FlipBox';
import SiteData from 'components/Common/SiteData';
import Transactions from 'components/Common/Transactions';

import { useDashboard } from 'dashboard';
import { useWeb3 } from 'web3';

import { formatNumber, inWindow } from 'utils';

const DashboardReinvestments = ({ location }) => {
  const { reinvestedHODL } = useDashboard();
  const { bsc } = useWeb3();

  return (
    <Layout location={location}>
      {inWindow() ? (
        <>
          <Root data-row-count="2">
            <FlipBox>
              <div>
                <h4 className="hodl">Tokens from reinvestment</h4>
                <strong>{formatNumber(reinvestedHODL, 0)} $HODL</strong>
                <small>
                  ${formatNumber(reinvestedHODL * bsc.hodlUsdPrice, 2)}
                </small>
              </div>
              <div>
                <SiteData
                  className="info"
                  path="dashboard.reinvestmentsTokensFromReinvestment"
                />
              </div>
            </FlipBox>
            <FlipBox>
              <div>
                <h4>Benefits of reinvesting</h4>
                <SiteData
                  className="info"
                  path="dashboard.reinvestmentsBenefitsOfReinvesting"
                />
              </div>
            </FlipBox>
          </Root>
          <Transactions location={location} type="reinvestments" round={true} />
        </>
      ) : (
        ''
      )}
    </Layout>
  );
};

export default DashboardReinvestments;
